/*jshint esversion: 6 */

import Ajax from "./Ajax";

let CountrySelector = (function(){

    let selector = '.dialog.modal.location.selector';

    let setupCountrySelectorInterface = function (dialogSelector) {
        selector = dialogSelector || selector;
        $(selector).dialog({
            autoOpen: false,
            draggable: false,
            resizable: false,
            width: $(window).width(),
            modal: true,
            dialogClass: "country selector",
            position: {
                my: "center top",
                at: "center top",
                of: "body"
            },
            title: "VPN simulation",
            show: {
                effect: "blind",
                duration: 500
            },
            hide: {
                effect: "blind",
                duration: 500
            },
            closeOnEscape: true,
        });
        $('.select.country').click(function(){
            $(selector).dialog("open");
        });
        $(selector + ' .country.button').click(function(){

            let currentIsocode = $(selector + ' td.current.isocode').text();
            let nextIsocode = $(this).text();
            let nextIp = $(this).attr('data-ip');
            let nextCountry = $(this).attr('title');

            // Modify the interface given the selection

            $(selector + ' .country.button').removeClass('active');
            $(this).addClass('active');

            $(selector + ' .next.location').text(nextIsocode);

            $(selector + ' button.browse')
                .removeClass('hidden')
                .attr('data-ip', nextIp)
            ;

            if (nextIsocode === currentIsocode) {
                $(selector + ' button.browse').removeClass('active');
            } else {
                $(selector + ' button.browse').addClass('active');
            }

            $(selector + ' button.browse span').text(` (${nextIsocode})`);

            $(selector + ' .next.isocode').text(nextIsocode);
            $(selector + ' .next.ipaddress').text(nextIp);
            $(selector + ' .next.country').text(nextCountry);
        });

        // Button which confirms selection and refreshes page
        $(selector + ' button.browse').click(function(){
            let fromIp = $(this).attr('data-ip');
            if (fromIp) {
                setIpAddress(fromIp);
            } else {
                alert('Unknown location');
            }
        });
    };

    // Makes an ajax call to set the ipaddress in the session
    let setIpAddressSuccess = function(){
        $(selector + ' .flash-message')
            .text('The page will now reload')
            .removeClass('hidden')
        ;
        location.reload();
    };
    let setIpAddressError = function(response){
        alert('There was an error: ' + response);
    };
    let setIpAddress = function(ipAddress){
        let route = window.location.protocol + '//' + window.location.hostname;
        if (ENV === 'dev') {
            //route = route + '/app_dev.php';
        }
        route = `${route}/en/widget/country/ip/${ipAddress}`;
        Ajax.getRequest(route, setIpAddressSuccess, setIpAddressError);
    };


    return {
        setupCountrySelectorInterface: setupCountrySelectorInterface,
    };

})();

export default CountrySelector;